.BlackBox {
  height: 339px;
  background: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}

.Maincard {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}

.title {
  color: #f7f7f7;
  font-size: 30px;
  font-weight: 700;
  margin-left: 12px;
  margin-right: 12px;
  word-wrap: break-word;
  text-align: center;
}

.subtitle {
  text-align: center;
  color: #f7f7f7;
  font-size: 18px;
  font-weight: 300;
  word-wrap: break-word;
  margin-left: 24px;
  margin-right: 24px;
}

.BxBxMapAlt {
  width: 15.79px;
  height: 9.82px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.Vector {
  width: 13.15px;
  height: 7.37px;
  background: black;
}

.PublicaTuCarro {
  /* Add your button styles here */
}
