/* MAIN CSS */

/* ELEMENTS */
body {
  margin: 0px;
  padding: 0;
}
line {
  display: inline-flex;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #6666;
  border-bottom-style: solid;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.pc {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: black;
}

/* LISTINGS */

.car-card {
  display: flex;
  max-width: 364px;
  flex-direction: column;
}
.car-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1.54;
  width: 100%;
  padding: 12px 0 0;
  color: #fff;
}
.car-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.hot-label {
  position: relative;
  border-radius: 8px;
  background-color: var(--Schemes-Shadow, #000);
  align-self: start;
  margin-left: 11px;
  white-space: nowrap;
  padding: 5px 9px;
  font: 500 12px Roboto, sans-serif;
}
.price-tag {
  position: relative;
  border-radius: 0 0 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 158px;
  width: 100%;
  letter-spacing: 0.99px;
  padding: 8px 60px;
  font: 700 22px Roboto, sans-serif;
}
.car-details {
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  margin-top: 7px;
  width: 100%;
  gap: 20px;
  font-weight: 700;
  padding: 0 19px;
}
.car-model {
  color: #2a2a2a;
  font: 700 20px Cambay, sans-serif;
}
.rating-wrapper {
  display: flex;
  gap: 7px;
  font-size: 18px;
  color: #00cd15;
  white-space: nowrap;
  margin: auto 0;
  padding: 0 2px;
}
.rating-score {
  font-family: Roboto, sans-serif;
}
.rating-icon {
  aspect-ratio: 1.05;
  object-fit: auto;
  object-position: center;
  width: 19px;
  fill: #006210;
  align-self: start;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.fw {
  width: 100%;
  box-sizing: border-box;
}
/* Adjust the size of date input fields */
input[type="date"] {
  width: 100% !important; /* Adjust the width as needed */
  text-align: start;
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}
input[type="date"] {
  -webkit-appearance: none;
  border: 1px solid #000000; /* Add a border */
  color: black;
  display: block;
  text-align: start;
  text-align: -webkit-left;
  background-color: white;
}

input::-webkit-date-and-time-value {
  text-align: left;
}
input::-webkit-textfield-decoration-container {
  text-align: left;
}
/* Target WebKit browsers like Safari */

/* Optionally, add some styling for better appearance */
input[type="date"]:focus {
  border-color: #000000; /* Change border color on focus */
}
input {
  border: 1px solid #000000; /* Add a border */
  color: black;
  font-size: 12px;
  padding: 8px;

  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}
.mobileInput {
  min-height: 32px;
  padding: 8px;
  font-size: 12px;
}
input::placeholder {
  color: #000000;

  font-size: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-box {
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
}
