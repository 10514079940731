/* FONTS CSS */
body {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 2.5em;
  font-weight: 700;
}
h2 {
  font-size: 2em;
  font-weight: 700;
}
h3 {
  font-size: 1.5em;
  font-weight: 700;
}
h4 {
  font-size: 1.2em;
  font-weight: 700;
}
h5 {
  font-size: 1em;
  font-weight: 900;
}
h6 {
  font-size: 0.8em;
  font-weight: 700;
}
p {
}
