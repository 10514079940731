/* BUTTONS*/
largeButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: #33d646;
  cursor: pointer;
}

squareButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 15px;
  font-weight: 900;
  width: fit-content;
  color: black;
  background-color: aqua;
}
.rounded {
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
}
.greenButton {
  background-color: #33d646;
  color: white;
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 700;
}
.redButton {
  background-color: rgb(73, 27, 27);
  color: white;
  cursor: pointer;
}
.redButton:hover {
  background-color: rgb(140, 0, 0);
}
.greenButton:hover {
  background-color: #219b2f;
}
/* MODAL */

modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
modalcard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

/* LAYOUT CSS */
.shadow {
  box-shadow: 0px 2px 5px 0px #a1a1a166;
}
.alignText {
  text-align: center;
}
iconrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  gap: 8px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
}
iconrow:hover {
  background-color: #cccccc;
  box-shadow: 0px 2px 5px 0px #d1d1d166;
}
hover {
  z-index: 2;
  display: none;
  height: 500px;
  width: 280px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px #d1d1d166;
  border-radius: 8px;
}
profiletab {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 68px;
  box-sizing: border-box;
  height: 32px;
}
profiletab:hover {
  hover {
    display: block;
  }
  background-color: #f5f5f5;
  box-shadow: 0px 2px 5px 0px #d1d1d166;
}
.inv {
  border-width: 0px !important;
  padding-block: 0px !important;
  padding-inline: 0px !important;
  outline: none !important;
}
.inv:focus-visible {
}

rectanglecard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  box-sizing: border-box;
  width: 750px;
  gap: 13px;
  border-radius: 85px;
  height: 64px;
  border: 0px solid #33d646;
  transition: all 0.1s;
}
rectanglecard:hover {
  padding-left: 22px;
  border: 3px solid #33d646;
}
bodycard {
  display: flex;
  flex-direction: column;

  padding: 13px;
  width: 100%;
  box-sizing: border-box;
  min-height: 90vh;
}
navbar {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  background-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #cacaca66;
}
pagewrapper {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
pagewrapperthin {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
mobilewrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  padding-top: 1.5em;
  width: 100%;
  max-width: 750px;
}
row {
  display: flex;
  width: 100%;
  flex-direction: row;
}
widecard {
  display: flex;
  justify-content: center;
}
container {
  display: flex;
  width: 100%;
}
cardlabel {
  background-color: white;
  padding: 2px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px #6666;
}
highlightBox {
  display: flex;
  box-sizing: border-box;
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
}
roundedCard {
  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  border-color: #6666;
  padding: 24px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
}
selectionCard {
  display: flex;
  background-image: url("https://images.turo.com/media/vehicle/images/xxkzP6xMTQKl8EfXNssR3w.215x137.jpg");
  width: 215px;
  height: 137px;
  background-size: cover;
}
.col {
  flex-direction: column;
}
column {
  display: flex;
  flex-direction: column;
}
.highlight {
  cursor: pointer;
  transition: border-color 0.3s;
}
.highlight:hover {
  border-color: rgb(46, 92, 0) !important;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.chkPanel {
  display: flex !important;
  flex-direction: column;
  gap: 16px;
  width: 60%;
  margin-right: 36px;
}
.chkSlider {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
}
.jcc {
  justify-content: center;
}
.jca {
  justify-content: space-around;
}
.jcb {
  justify-content: space-between;
}

.jce {
  justify-content: flex-end;
}
.jcs {
  justify-content: flex-start;
}

.aic {
  align-items: center;
}

.iconGap {
  column-gap: 16px;
}

/* GAPS */

.gap {
  margin-bottom: 8px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mt-1 {
  margin-top: 4px;
}

.mb-1 {
  margin-bottom: 4px;
}

.my-l {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mt-l {
  margin-top: 24px;
}

.mb-l {
  margin-bottom: 24px;
}
.mt-m {
  margin-top: 16px;
}
.mt-sm {
  margin-top: 12px;
}
.g1 {
  gap: 4px;
}
.g-sm {
  gap: 12px;
}
.g-m {
  gap: 16px;
}
.g-l {
  gap: 24px;
}

.my-m {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-sm {
  margin-bottom: 12px;
  margin-top: 12px;
}

.my-xs {
  margin-bottom: 8px;
  margin-top: 8px;
}
.my-xxs {
  margin-bottom: 4px;
  margin-top: 4px;
}
