/* MOBILE QUERIES */

@media (max-width: 768px) {
  .redButton {
    font-size: 12px;
  }
  .greenButton {
    font-size: 12px;
  }
  modalcard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10em;
    padding: 16px;
    height: 90vh;
  }
  .onMobile {
    display: flex;
  }
}

@media (min-width: 540px) {
}

@media (min-width: 769px) {
}

/* DESKTOP QUERIES */

@media (max-width: 992px) {
  .onDesktop {
    display: none;
  }
  selectionCard {
    display: flex;

    width: 110px;
    height: 70px;
  }
}

@media (min-width: 993px) {
  .onMobile {
    display: none;
  }
  pagewrapper {
    margin-top: 2em;
    margin-bottom: 2em;

    max-width: 940px;
  }
  pagewrapperthin {
    width: 30vw;
  }

  .onDesktop {
    display: flex;
  }
}
