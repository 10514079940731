
.fs{
    height: 50vh;
    width: 100%;
}
button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #666;
    border-color: #ccc;
}
button:disabled:hover {
    background-color: #ccc;
    color: #666;
    border-color: #ccc;
}

.list-your-car-container {
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
#listBody {
    padding: 0;
    margin-bottom: 3em !important;
}
@media (max-width: 768px) {
    .nopad {
        padding: 0px !important;
    }
    #listBody {
        width: 100vw;
        margin: 0 auto;
    }
}
@media (min-width: 768px) {
    #listBody {
        width: 80vw;
        margin: 0 auto;
    }

    .step.expanded {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (min-width: 1200px) {
    #listBody {
        width: 40vw;
        margin: 0 auto;
    }
}
.tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    user-select: none;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.tab:hover {
    background-color: #f5f5f5;
}
.tab .arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-right: 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
}
.tab .label {
    flex-grow: 1;
    padding-left: 1em;
}
.review-section {
    max-height: none;
    overflow: visible;
}
.checkmark {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ccc;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}
.tab.completed .checkmark {
    background-color: green;
    animation: showCheck 1s forwards;
}
.tab.incomplete .checkmark {
    background-color: #ccc;
}
.tab.open .arrow {
    display: none;
}
@keyframes showCheck {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .step {
        padding: 0.6em;
    }
}
label {
    min-width: 120px;
}
.step {
    position: relative;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    padding: 0 20px;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
}
.step.expanded {
    max-height: 1000px; /* or any maximum height you expect the content to have */
    opacity: 1;
    padding: 20px;
}
.step.hidden {
    display: none;
}
.error-message {
    color: red;
    font-size: 14px;
    display: block;
}
select.error,
input.error {
    border-color: red;
}
#map {
    width: 100%;
    height: 200px;
    position: relative;
    display: none;
}
.circle {
    width: 100px;
    height: 100px;
    background-color: rgba(0, 255, 0, 0.3);
    border-radius: 50%;
    pointer-events: none;
    border: 2px solid rgba(0, 128, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.map-info {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}
#address-input {
    display: none;
}
.features-container {
    display: flex;
    flex-wrap: wrap;
}
.features-container label {
    width: 30%;
    margin-bottom: 10px;
}
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: border-color 0.3s ease;
}
.dropzone:hover {
    border-color: #aaa;
}
.image-previews {
    display: flex;
    flex-wrap: wrap;
}
.image-preview,
.image-placeholder {
    width: 80px;
    height: 80px;
    margin: 5px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    transition: transform 0.3s ease;
}
.image-preview img {
    max-width: 100%;
    max-height: 100%;
}
.image-placeholder {
    background-color: #f0f0f0;
}
.image-preview:hover {
    transform: scale(1.05);
}
.review-section {
    margin-top: 20px;
}
.review-section h4 {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}
.review-section p,
.review-section ul {
    margin: 5px 0;
    font-size: 14px;
}
.review-section ul {
    padding-left: 20px;
    list-style-type: disc;
}
.bottomLine {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-left: 4vw;
    margin-right: 4vw;
}
