/* Margin utilities */
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-1 {
  margin: 4px;
}
.mt-1 {
  margin-top: 4px;
}
.mr-1 {
  margin-right: 4px;
}
.mb-1 {
  margin-bottom: 4px;
}
.ml-1 {
  margin-left: 4px;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}
.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.m-2 {
  margin: 8px;
}
.mt-2 {
  margin-top: 8px;
}
.mr-2 {
  margin-right: 8px;
}
.mb-2 {
  margin-bottom: 8px;
}
.ml-2 {
  margin-left: 8px;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m-3 {
  margin: 12px;
}
.mt-3 {
  margin-top: 12px;
}
.mr-3 {
  margin-right: 12px;
}
.mb-3 {
  margin-bottom: 12px;
}
.ml-3 {
  margin-left: 12px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.m-4 {
  margin: 16px;
}
.mt-4 {
  margin-top: 16px;
}
.mr-4 {
  margin-right: 16px;
}
.mb-4 {
  margin-bottom: 16px;
}
.ml-4 {
  margin-left: 16px;
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.m-5 {
  margin: 20px;
}
.mt-5 {
  margin-top: 20px;
}
.mr-5 {
  margin-right: 20px;
}
.mb-5 {
  margin-bottom: 20px;
}
.ml-5 {
  margin-left: 20px;
}
.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}
.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-6 {
  margin: 24px;
}
.mt-6 {
  margin-top: 24px;
}
.mr-6 {
  margin-right: 24px;
}
.mb-6 {
  margin-bottom: 24px;
}
.ml-6 {
  margin-left: 24px;
}
.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}
.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

/* Padding utilities */
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-1 {
  padding: 4px;
}
.pt-1 {
  padding-top: 4px;
}
.pr-1 {
  padding-right: 4px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pl-1 {
  padding-left: 4px;
}
.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.p-2 {
  padding: 8px;
}
.pt-2 {
  padding-top: 8px;
}
.pr-2 {
  padding-right: 8px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pl-2 {
  padding-left: 8px;
}
.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-3 {
  padding: 12px;
}
.pt-3 {
  padding-top: 12px;
}
.pr-3 {
  padding-right: 12px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pl-3 {
  padding-left: 12px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-4 {
  padding: 16px;
}
.pt-4 {
  padding-top: 16px;
}
.pr-4 {
  padding-right: 16px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pl-4 {
  padding-left: 16px;
}
.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-5 {
  padding: 20px;
}
.pt-5 {
  padding-top: 20px;
}
.pr-5 {
  padding-right: 20px;
}
.pb-5 {
  padding-bottom: 20px;
}
.pl-5 {
  padding-left: 20px;
}
.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}
.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-6 {
  padding: 24px;
}
.pt-6 {
  padding-top: 24px;
}
.pr-6 {
  padding-right: 24px;
}
.pb-6 {
  padding-bottom: 24px;
}
.pl-6 {
  padding-left: 24px;
}
.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}
.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
